<template>
    <div class="container content">
        <div>
        <b-breadcrumb separator="has-succeeds-separator" size="is-medium">
            <b-breadcrumb-item tag='router-link' to="/admin-dashboard">Admin</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="/admin-companies">Yritykset</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' :to="'/admin-companies/'+$route.params.companyId" active>{{ company_name }}</b-breadcrumb-item>
        </b-breadcrumb>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Yritystiedot</p>
                    <div class="columns">
                        <div class="column is-9">
                            <b-field label="Yritys">
                                <b-input v-model="company.name"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-3">
                            <b-field label="Y-tunnus">
                                <b-input v-model="company.vat"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <b-field label="Osoite">
                                <b-input v-model="company.address"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field label="Postinumero">
                                <b-input v-model="company.zip"></b-input>
                            </b-field> 
                        </div>
                        <div class="column is-6">
                            <b-field label="Kunta">
                                <b-input v-model="company.city"></b-input>
                            </b-field> 
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                    <b-field label="Palautus %">
                                <b-input v-model="company.return"></b-input>
                            </b-field> 
                            </div></div>
                                        <div style="text-align: right; margin-top: 20px;">
                        <b-button @click="saveCompany" size="is-medium">
                           Tallenna muutokset
                        </b-button>
                    </div>
                </article>            
            </div>
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Yrityksen ylläpitäjäkäyttäjät</p>
                    <b-table hoverable :data="users" :columns="user_columns"  @click="openUserCard"></b-table>
                    <div style="text-align: right; margin-top: 30px;">
                        <b-button size="is-medium"
                            @click="openNewUserModal"
                            icon-left="plus">
                            Uusi käyttäjä
                        </b-button>
                    </div>
                </article>
            </div>
        </div>  
        <div class="tile is-ancestor">
          
            <div class="tile is-parent is-6">
                <article class="tile is-child box">
                    <p class="title">Yrityksen työntekijät</p>
                    <b-table hoverable :data="employees" :columns="employee_columns"  @click="openEmployeeCard"></b-table>
                    <div style="text-align: right; margin-top: 30px;">
                        <div v-if="1 == 2" style="background-color: white;
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding: 0px;
    font-size: 1.25rem;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    float: left;
    cursor: pointer;

"><b-field class="file is-primary" :class="{'has-name': !!file}">
                            <b-upload v-model="file" @change="readFile" class="">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">CSV</span>
                                </span>
                            </b-upload>
                        </b-field>
                        </div>
                        <b-button size="is-medium"
                            @click="openNewEmployeeModal"
                            icon-left="plus">
                            Uusi työntekijä
                        </b-button>
                        <b-button size="is-medium"
                            @click="openNewOrder"
                            icon-left="plus">
                            Muutosturva tilaus
                        </b-button>
                    </div>
                </article>
            </div>
        </div>

        <b-modal
            v-model="modals.new_user"
            has-modal-card
            full-screen
            :can-cancel="true">
            <NewUserForm :company="company" />
        </b-modal>
        <b-modal
            v-model="modals.new_employee"
            has-modal-card
            :can-cancel="true">
            <NewEmployeeForm :company="company" />
        </b-modal>
        <b-modal
            v-model="modals.new_order"
            has-modal-card
            full-screen
            @close="closeOrderModal"
            :can-cancel="true">
            <NewOrderForm @close="closeOrderModal" :duunarit="employees" :company="company" />
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/eventBus';
import NewUserForm from '@/views/admin/forms/NewUser'
import NewEmployeeForm from '@/views/admin/forms/NewEmployee'
import NewOrderForm from '@/views/admin/forms/NewOrder'
export default {
        components: {
            NewUserForm,
            NewEmployeeForm,
            NewOrderForm,
        },
        data() {
            return {
                file: null,
                content: null,
                parseCsv: null,
                modals: {
                    new_order: false,
                    new_user: false,
                    new_employee: false
                },
                company_name: "",
                company: {
                    id: "",
                    name: "",
                    vat: "",
                    address: "",
                    city: "",
                    zip: "",
                    billing_type: "paper",
                    e_invoice_address: "",
                    e_invoice_provider: "",
                    return: 0,
                },
                users: [],
                employees: [],
                employee_columns: [
                    {
                        field: 'first_name',
                        label: 'Etunimi'
                    },
                    {
                        field: 'last_name',
                        label: 'Sukunimi'
                    },
                    {
                        field: 'email',
                        label: 'Sähköposti'
                    },
                    {
                        field: 'mobile',
                        label: 'Puhelinnumero'
                    }
                ],
                user_columns: [
                    {
                        field: 'first_name',
                        label: 'Etunimi'
                    },
                    {
                        field: 'last_name',
                        label: 'Sukunimi'
                    },
                    {
                        field: 'email',
                        label: 'Sähköposti'
                    },
                    {
                        field: 'mobile',
                        label: 'Puhelinnumero'
                    }
                ]
            }
        },
        methods: {
            readFile(){
                const csvFile = document.getElementById("file");
                const input = csvFile.files[0];
                const reader = new FileReader();
                reader.onload = function (event) {
                    console.log(event.target.result); // the CSV content as string
                };
                reader.readAsText(input);

/*
                if (this.file.name.includes(".csv")) {
                    reader.onload = (res) => {
                    this.content = res.target.result;
                    };
                    reader.onerror = (err) => console.log(err);
                    reader.readAsText(this.file);
                } else {
                    this.content = "check the console for file output";
                    reader.onload = (res) => {
                    console.log(res.target.result);
                    };
                    reader.onerror = (err) => console.log(err);
                    reader.readAsText(this.file);
                }*/
            },
            openCSVModal() {

            },
            closeOrderModal() {
                console.log('closeOrderModal');
                this.modals.new_order = false;
            },
            openNewOrder() {
                console.log(this.employees);
                this.modals.new_order = true;
            },
            saveCompany() {
                axios.put('/companies/'+ this.$route.params.id, this.company).then((response) => {
                    this.company = response.data;
                    this.company_name = response.data.name;
                });
            },
            openNewUserModal() {
                this.modals.new_user = true;
            },
            openNewEmployeeModal() {
                this.modals.new_employee = true;
            },
            testi(data) {
                console.log(data);
            },
            updateCompanyData() {
                axios.get('/companies/'+ this.$route.params.id).then((response) => {
                    this.company = response.data;
                    this.company_name = response.data.name;
                });
            },
            updateUserList() {
                axios.get('/companies/'+ this.$route.params.id+'/users').then((response) => {
                    this.users = response.data;
                });
            },
            updateEmployeeList() {
                axios.get('/companies/'+ this.$route.params.id+'/employees').then((response) => {
                    this.employees = response.data;
                });
            },
            openUserCard(user) {
                this.$router.push({ name: 'AdminUser', params: { id: this.company.id, userId: user.id }});
            },
            openEmployeeCard(employee) {
                this.$router.push({ name: 'AdminEmployee', params: { id: this.company.id, employeeId: employee.id }}); 
            }
        },
        computed: {
            companies() {
                return this.$store.getters['getCompanies']
            },
            isFinvoice() {
                if(this.company.billing_type != "paper")
                return "selected";
                return false;
            }
        },
        mounted() {
            this.updateCompanyData();
            this.updateUserList();
            this.updateEmployeeList();            
        },
        created() {
            eventBus.$on('admin-created-user',(() => {
                this.updateUserList();
            }))
            eventBus.$on('admin-created-employee',(() => {
                this.updateEmployeeList();
            }))
        }
    }
</script>
<style>
.breadcrumb {
    margin-bottom: 20px;
}
.breadcrumb li {
    margin-top: 0px !important;
}
tr {
    cursor: pointer;
}

.is-hoverable tr:hover {
    background-color: #cecece !important;
}

</style>